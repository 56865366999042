







































import { MnemonicVerificationTs } from './MnemonicVerificationTs';
export default class MnemonicVerification extends MnemonicVerificationTs {}
