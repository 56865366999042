
























import VerifyMnemonicTs from './VerifyMnemonicTs';
export default class VerifyMnemonic extends VerifyMnemonicTs {}
